import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import HeroBackground from '../assets/img/support-center-hero-background.jpg';
import Accordion from '../components/Accordion/Accordion';
import {InfoPointIcon, PortalIcon, QuestionPointIcon} from '../assets/icons';
import SEO from '../components/SEO/SEO';

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16rem 6.4rem;
  background: url(${HeroBackground}) center / cover no-repeat;
  border-radius: var(--border-radius-surface) var(--border-radius-surface) 0 0;
  @media only screen and (max-width: 1366px) {
    padding: 16rem 2.4rem;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
  background: var(--color-surface-light);
  border-radius: 0 0 var(--border-radius-surface) var(--border-radius-surface);
`;

const Tabs = styled.div`
  position: absolute;
  display: flex;
  top: -4rem;
  width: 100%;
  padding: 0 6.4rem;
  @media only screen and (max-width: 1366px) {
    padding: 0 2.4rem;
  }
`;

const Tab = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 40rem;
  height: 8rem;
  font-size: 2.4rem;
  font-weight: 700;
  color: ${props => props.active ? 'var(--color-heading-dark)' : 'rgba(16, 66, 73, 0.7)'};
  background: #ffffff;
  border-radius: var(--border-radius-element);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  /* cursor: pointer; */
  :not(:last-child) {
    margin-right: 4.8rem;
  }
  ::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    right: 0;
    height: 0.4rem;
    background: ${props => props.active ? '#104249' : 'transparent'};
  }
`;

const Faq = styled.div`
  padding: 12rem 2.4rem;
  width: 100%;
  max-width: var(--page-content-width);
  margin: 0 auto;
  h2, h3 {
    color: var(--color-heading-dark);
  }
  p {
    color: var(--color-body-dark);
  }
  a {
    position: relative;
    font-weight: bold;
    color: var(--color-body-dark);
    transition: opacity 0.15s ease-in-out;
    ::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -0.2rem;
      width: 100%;
      height: 0.2rem;
      background: var(--color-body-dark);
      transform: translateY(0);
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }
    :hover ::after {
      opacity: 1;
      transform: translateY(0.2rem);
    }
  }
`;

const Glossary = styled.div`
  display: flex;
  justify-content: center;
  margin: 6.4rem 0 0;
  div {
    padding: 4.8rem;
    background: #ffffff;
    border-radius: var(--border-radius-surface);
    box-shadow: 0px 38px 80px rgba(0, 0, 0, 0.07), 0px 11.4559px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 4.75819px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.72094px 3.62304px rgba(0, 0, 0, 0.0243888);
  }
`;

const GlossaryItem = styled.p`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 2rem;
  }
  img {
    margin-right: 2rem;
  }
`;

const KlowledgeBase = styled.div`
  display: flex;
  h2, h3 {
    color: var(--color-heading-dark);
  }
  p {
    color: var(--color-body-dark);
  }
`;

const KlowledgeBaseContent = styled.article`
  padding: 12rem 6.4rem;
  flex: 1;
`;

const KlowledgeBaseSideMenu = styled.div`
  flex: 0 0 50rem;
  padding: 12rem 6.4rem;
  background: #E6EAE9;
  border-radius: 0 0 var(--border-radius-surface) 0;
`;

const Nav = styled.nav`
  
`;

const MenuItem = styled.li`
  font-size: 1.8rem;
  font-weight: 700;
  list-style: none;
  color: var(--color-body-dark);
    :not(:last-child) {
      margin-bottom: 4.8rem;
    }
`;

const accordionData = [
  {
    title: 'How do I create my own 360 images?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, fugit.',
  },
  {
    title: 'Can I get any free 360 images?',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto ad corporis quod corrupti ducimus nesciunt eaque? Nostrum nam officia fugit.',
  },
  {
    title: 'What panorama formats are supported?',
    content: 'Lorem ipsum dolor sit, amet consectetur adipisicing.',
  },
  {
    title: 'Fair Use of the Platform',
    content: 'SeekBeak wants all of our customers to get the best value and quality from our unlimited plans, and have a lenient Fair Usage Policy to prevent fraud and abuse of our Services. If we feel that an account is using our platform in an abusive and/or fraudulent manner, the account owner will be contacted and action will be taken on a case by case basis.',
  },
];

const showAccordion = false;

const SupportCenterPage = ({ location }) => {
  const [activeTab, setActiveTab] = useState('faq');

  return (
    <>
      <SEO title='' description='Need some help with the veerXR platform? Search through our knowledge base and FAQ to learn more on how to effectively use our 360 VR creator.' location={location} />
      <Hero>
        <HeroContent>
          <h1>Support center</h1>
          <p className='subtitle'>Get help with most common problems</p>
        </HeroContent>
      </Hero>

      <ContentWrapper>
        <Tabs>
          <Tab onClick={() => setActiveTab('faq')} active={activeTab === 'faq'} >FAQ</Tab>
          {/* <Tab onClick={() => setActiveTab('knowledgeBase')} active={activeTab === 'knowledgeBase'} >Knowledge base</Tab> */}
        </Tabs>

        {activeTab === 'faq'
          ? <Faq>
              <p>Here are some frequently asked questions about our platform. If you have an issue you don’t see an<br />answer to here, <Link to='/contact-us/'>contact us</Link> so we can help!</p>
              <Glossary>
                <div>
                  <h3>Glossary of Platform Terminology</h3>
                  <GlossaryItem>
                    <span><strong>Experience</strong> - a group of POIs (points of interest) connected in a cohesive way</span>
                  </GlossaryItem>
                  <GlossaryItem>
                    <span><strong>Point of Interest</strong> - a 360 image, 360 video, map or image scene within an experience</span>
                  </GlossaryItem>
                  <GlossaryItem>
                    <img src={InfoPointIcon} />
                    <span><strong>Information Point</strong> - a panel containing information such as text, video, or audio</span>
                  </GlossaryItem>
                  <GlossaryItem>
                    <img src={PortalIcon} />
                    <span><strong>Portal</strong> - for jumping from one scene directly to another one</span>
                  </GlossaryItem>
                  <GlossaryItem>
                    <img src={QuestionPointIcon} />
                    <span><strong>Question Point</strong> - a panel of multiple-choice type questions</span>
                  </GlossaryItem>
                </div>
              </Glossary>
              
              {showAccordion
                ? <>
                  <h2>General Questions</h2>
                  <Accordion data={accordionData} />
                  </>
                : null}
            </Faq>

          : <KlowledgeBase>
              <KlowledgeBaseContent>
                <h2>Knowledge base</h2>
              </KlowledgeBaseContent>
              <KlowledgeBaseSideMenu>
                <Nav>
                  <ul>
                    <MenuItem>Sit amet</MenuItem>
                    <MenuItem>Consectetur adipisicing elit</MenuItem>
                    <MenuItem>Vero deleniti ipsum est hic</MenuItem>
                  </ul>
                </Nav>
              </KlowledgeBaseSideMenu>
            </KlowledgeBase>}
      </ContentWrapper>
    </>
  )
}

export default SupportCenterPage;
