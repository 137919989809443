import React, { useState } from 'react';
import styled from 'styled-components';

const AccordionWrapper = styled.div``;

const AccordionItem = styled.div`
  padding: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border-radius: var(--border-radius-element);
  cursor: pointer;
  :not(:last-child) {
      margin-bottom: 1rem;
  }
`;

const AccordionTitle = styled.h3`
    margin-bottom: 0;
    font-size: 1.8rem;
`;

const AccordionContent = styled.p`
  display: ${props => props.active ? 'block' : 'none'};
  margin-top: 2rem;
`;

const Accordion = ({ data }) => {
    const [activeAccordion, setactiveAccordion] = useState(3);
    
    return (
        <AccordionWrapper>
            {data.map((item, index) => (
                <AccordionItem onClick={() => setactiveAccordion(activeAccordion === index ? null : index)}>
                    <AccordionTitle>
                        {item.title}
                    </AccordionTitle>
                    <AccordionContent active={activeAccordion === index}>
                        {item.content}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </AccordionWrapper>
    )
}

export default Accordion;
